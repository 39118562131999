/** @define table-wrapper */

.table-wrapper {
  box-sizing: border-box;

  @apply align-middle inline-block min-w-full shadow overflow-hidden border-b border-gray-200;
}

.table-wrapper__table {
  box-sizing: border-box;

  @apply table-fixed min-w-full w-full;
}

.table-wrapper__table--fixed {
  box-sizing: border-box;

  @apply table-fixed;
}

.table-wrapper__th {
  box-sizing: border-box;

  @apply px-6 py-3 border-b border-gray-200 bg-litlingo-input-background text-left text-xs leading-4  text-gray-700 uppercase tracking-wider font-bold;
}

.table-wrapper__th--w-2 {
  box-sizing: border-box;
  width: 2%;
}

.table-wrapper__th--w-3 {
  box-sizing: border-box;
  min-width: 36px;
  width: 36px;
  max-width: 36px;
}

.table-wrapper__th--w-4 {
  box-sizing: border-box;
  width: 4%;
}

.table-wrapper__th--w-5 {
  box-sizing: border-box;
  width: 5%;
}

.table-wrapper__th--w-6 {
  box-sizing: border-box;
  width: 6%;
}

.table-wrapper__th--w-7 {
  box-sizing: border-box;
  width: 7%;
}

.table-wrapper__th--w-8 {
  box-sizing: border-box;

  @apply w-1/12;
}

.table-wrapper__th--w-12 {
  box-sizing: border-box;
  width: 12%;
}

.table-wrapper__th--w-13 {
  box-sizing: border-box;
  width: 13%;
}

.table-wrapper__th--w-14 {
  box-sizing: border-box;
  width: 14%;
}

.table-wrapper__th--w-15 {
  box-sizing: border-box;
  width: 15%;
}

.table-wrapper__th--w-16 {
  box-sizing: border-box;
  width: 16%;
}

.table-wrapper__th--w-18 {
  box-sizing: border-box;
  width: 18%;
}

.table-wrapper__th--w-20 {
  box-sizing: border-box;

  @apply w-1/5;
}

.table-wrapper__th--w-22 {
  box-sizing: border-box;
  width: 22%;
}

.table-wrapper__th--w-25 {
  box-sizing: border-box;

  @apply w-1/4;
}

.table-wrapper__th--w-30 {
  box-sizing: border-box;
  width: 30%;
}

.table-wrapper__th--w-35 {
  box-sizing: border-box;
  width: 35%;
}

.table-wrapper__th--w-40 {
  box-sizing: border-box;
  width: 40%;
}

.table-wrapper__th--w-40--responsive {
  box-sizing: border-box;

  @apply w-2/12;
}

.table-wrapper__th--w-45 {
  box-sizing: border-box;
  width: 45%;
}

.table-wrapper__th--w-45--responsive {
  box-sizing: border-box;

  @apply w-2/12;
}

.table-wrapper__th--w-50 {
  box-sizing: border-box;

  @apply w-2/4;
}

.table-wrapper__th--w-80 {
  box-sizing: border-box;

  @apply w-4/5;
}

.table-wrapper__tbody {
  box-sizing: border-box;

  @apply bg-white;
}

.table-wrapper__td {
  box-sizing: border-box;

  @apply px-6 py-4 border-b border-gray-200 text-sm leading-5 text-litlingo-body w-1/3;
}

.table-wrapper__td--design {
  box-sizing: border-box;

  @apply px-6 py-1 border-b border-gray-200 text-sm leading-5 text-litlingo-body w-1/3;
}

.table-wrapper__td--align-left {
  box-sizing: border-box;

  @apply text-left;
}

.table-wrapper__td--truncate {
  box-sizing: border-box;

  @apply truncate;
}

/* new styles */
.table-wrapper__container::after {
  height: 0;
  display: block;
  position: sticky;
  width: 100%;
  content: '';
  bottom: 0;

  @apply border-b border-litlingo-gray-1 rounded;
}

.table-wrapper__new {
  box-sizing: border-box;

  @apply align-middle inline-block min-w-full border border-litlingo-gray-1 rounded;
}

.table-wrapper__new-th {
  box-sizing: border-box;

  @apply pt-3.5 pb-2.5 bg-litlingo-gray-1 text-left font-header text-xss leading-4 font-bold text-litlingo-gray-5 tracking-wider whitespace-no-wrap truncate;
}

.table-wrapper__new-th-filter {
  box-sizing: border-box;

  @apply pt-3.5 pb-2.5 bg-litlingo-secondary-80 text-left font-header text-xss leading-4 font-bold text-litlingo-gray-5 tracking-wider whitespace-no-wrap truncate;
}

.table-wrapper__new-tr {
  box-sizing: border-box;
  z-index: 0;

  @apply bg-white text-left border-b border-litlingo-gray-1 text-xss leading-4 text-litlingo-gray-5;
}

.table-wrapper__new-tr:hover {
  z-index: 10;
  filter: drop-shadow(0 0 8px rgba(0 0 0 / 25%));
}

.table-wrapper__new-tr input:hover {
  @apply border-litlingo-success;
}

.table-wrapper__new-tr:hover .table-wrapper__new-td--view-section {
  display: flex;
}

.table-wrapper__logic-builder-tr:hover {
  z-index: 10;
  filter: drop-shadow(0 0 8px rgb(0 0 0 / 25%));
}

.table-wrapper__new-td {
  box-sizing: border-box;

  @apply p-0 text-xss leading-5 text-litlingo-gray-5;
}

.table-wrapper__new-cell-content {
  box-sizing: border-box;
  height: 118px;

  @apply pb-2 pt-2 pr-4 overflow-hidden text-xss leading-5 text-litlingo-gray-5;
}

.table-wrapper__new-cell-content-condensed {
  box-sizing: border-box;
  height: 36px;

  @apply pb-2 pt-2 pr-4 overflow-hidden text-xss leading-5 text-litlingo-gray-5;
}

.table-wrapper__new-td--view-section {
  display: none;
}

.table-wrapper__new-cell-content-expanded {
  height: auto;
}

.table-wrapper__new-td:first-child .table-wrapper__new-cell-content {
  @apply pl-2;
}

.table-wrapper__new-td:last-child .table-wrapper__new-cell-content {
  @apply pr-2;
}

@screen sm {
  .table-wrapper {
    box-sizing: border-box;

    @apply rounded-lg;
  }
}

@screen xl {
  .table-wrapper__th--w-45--responsive {
    box-sizing: border-box;

    @apply w-9/20;
  }

  .table-wrapper__th--w-40--responsive {
    box-sizing: border-box;

    @apply w-2/5;
  }
}

/** end */
